<template>
  <hub-my-inventions />
</template>

<script>
import MyInventions from '../components/reports/MyInventionsReport.vue';

export default {
  components: {
    'hub-my-inventions': MyInventions
  },
  mounted() {}
};
</script>
